<template>
  <div class="balance bgblue">
    <!-- 结算 -->
    <!-- 搜索框部分 -->
    <el-card class="form-card mt5">
      <div class="form">
        <el-form
          :model="form"
          class="demo-form-inline"
          style="position: relative"
          label-width="110px"
        >
          <el-row>
            <el-col :span="4"
              ><el-form-item label="结算对象:">
                <el-input
                  v-model="form.user"
                  placeholder="请输入结算对象"
                ></el-input> </el-form-item
            ></el-col>
            <!-- <el-col :span="4"
            ><el-form-item label="金融单号:">
              <el-input
                v-model="form.user"
                placeholder="请输入金融单号"
              ></el-input> </el-form-item
          ></el-col>
        -->
            <el-col :span="4"
              ><el-form-item label="用户姓名:">
                <el-input
                  v-model="form.user"
                  placeholder="请输入用户姓名"
                ></el-input> </el-form-item
            ></el-col>
            <el-col :span="4"
              ><el-form-item label="业务类型:">
                <el-select
                  v-model="inverterSN.region"
                  placeholder="请选择业务类型:"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in inverterSN"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>

            <el-col :span="4">
              <el-form-item label="状态：">
                <el-select
                  v-model="explorationStatus.region"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in explorationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>

            <el-col :span="4" style="margin-left: 50px" class="mt5">
              <el-button type="primary" size="small">
                <i class="el-icon-search"></i>查询</el-button
              >
              <el-button plain size="small">
                <i class="el-icon-refresh"></i>重置</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <!-- 表格部分 -->
    <el-card class="form-card mt5">
      <div class="table">
        <el-button type="warning">导出</el-button>
        <el-button type="success">已结算￥10000000</el-button>
        <el-button type="danger">未结算￥10000000</el-button>
        <el-button type="primary">待确认￥10000000</el-button>
        <el-table
          :data="tableData"
          border
          height="calc(100vh - 250px)"
          :header-cell-style="{ background: '#f2f2f2', color: '#333' }"
        >
          <el-table-column
            prop="businessType"
            label="业务类型"
            align="center"
            :show-overflow-tooltip="true"
           
          >
          </el-table-column>

          <el-table-column
            prop="userName"
            label="用户姓名"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="balance"
            label="结算"
            align="center"
            width="190"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="componentPieces"
            label="组件片数"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

          <el-table-column
            prop="accountingName"
            label="核算名称"
            align="center"
            width="110"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="price"
            label="单价(元/板)"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

          <el-table-column
            prop="totalPice"
            label="总计(元)"
            align="center"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <el-table-column
            prop="status"
            label="状态"
            align="center"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <el-button v-if="row.status == 0" class="btn-green" type="text"
                >已结算</el-button
              >
              <el-button v-if="row.status == 1" class="btn-red" type="text"
                >未结算</el-button
              >
              <el-button v-if="row.status == 2" class="btn-orange" type="text"
                >已确认</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentTask"
            label="付款任务单据号"
            align="center"
            width="230"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        
       
          <el-table-column
            prop="time"
            label="生成时间"
            align="center"
            width="140"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="initiationTime"
            label="发起时间"
            width="140"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="agentTime"
            label="代理商确认时间"
            align="center"
            width="140"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="paymentTime"
            label="支付申请时间"
            width="140"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="paymentCompleted"
            label="支付完成时间"
            width="140"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        </el-table>
        <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[20, 30, 50, 100]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: right;"
        >
        </el-pagination>
      </div>
      </div>

    
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        
      },
      total: 0,
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 20,
      },
      inverterSN: [],
      explorationStatus:[],
      user:[],
      tableData: [
        {
          businessType: "租吾顶-户用",
          userName: "石德东",
          balance: "安徽省裕来新能源科技有限公司",
          componentPieces: "40",
          accountingName: "基础费用-完工费",
          price: "228.5",
          totalPice: "5941",
          paymentTask: "LEDGERDMSdivide0000000000006639-divide0000000000007440",
          time: "2024-03-20 17:10:50",
          initiationTime:'2024-03-21 09:19:08',
          agentTime:'2024-03-21 09:48:57',
          paymentTime:"2024-03-21 09:54:36",
          paymentCompleted:"2024-03-21 12:50:32",
        },
        
        {
          businessType: "租吾顶-户用",
          userName: "石德东",
          balance: "安徽省裕来新能源科技有限公司",
          componentPieces: "40",
          accountingName: "基础费用-完工费",
          price: "228.5",
          totalPice: "5941",
          paymentTask: "LEDGERDMSdivide0000000000006639-divide0000000000007440",
          time: "2024-03-20 17:10:50",
          initiationTime:'2024-03-21 09:19:08',
          agentTime:'2024-03-21 09:48:57',
          paymentTime:"2024-03-21 09:54:36",
          paymentCompleted:"2024-03-21 12:50:32",
        },
        {
          businessType: "租吾顶-户用",
          userName: "石德东",
          balance: "安徽省裕来新能源科技有限公司",
          componentPieces: "40",
          accountingName: "基础费用-完工费",
          price: "228.5",
          totalPice: "5941",
          paymentTask: "LEDGERDMSdivide0000000000006639-divide0000000000007440",
          time: "2024-03-20 17:10:50",
          initiationTime:'2024-03-21 09:19:08',
          agentTime:'2024-03-21 09:48:57',
          paymentTime:"2024-03-21 09:54:36",
          paymentCompleted:"2024-03-21 12:50:32",
        },
      ],
    };
  },
  methods: {
    handleSizeChange(){},
    handleCurrentChange(){},
  },
};
</script>

<style lang="less">
// 结算
.balance {
  padding: 10px;
  .form {
    padding-top: 10px;
    padding: 10px;
    padding-bottom: 0px !important;
  }
  .table {
    padding: 10px;
  }
 .el-card__body {
    padding: 0px !important;
  }
  .el-card {
    border-radius: 10px;
  }
  .el-table{
    margin-top: 10px;
  }
}
</style>